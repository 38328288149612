<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Blog Category</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="blogCategory.name"
                :error="$v.blogCategory.name.$error"
                outlined
                dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.blogCategory.name.$error"
                >Name is required.</span
              >
              <span class="text-danger" v-if="errors.name"
                >* {{ errors.name[0] }}</span
              >
            </v-col>

            <v-col cols="6" md="6">
              Is this a main category ?
              <v-switch
                v-model="blogCategory.is_parent"
                :label="blogCategory.is_parent ? 'Main' : 'Child'"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="!blogCategory.is_parent">
              <v-select
                label="Parent"
                v-model="blogCategory.parent_id"
                :error="$v.blogCategory.parent_id.$error"
                :items="blogCategories"
                item-text="name"
                item-value="id"
                outlined
                dense
              >
                <template v-slot:label>
                  Parent <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.blogCategory.parent_id.$error"
                >This Field is required.</span
              >
            </v-col>

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                :config="editorConfig"
                v-model="blogCategory.description"
              >
              </ckeditor>
              <span
                class="text-danger"
                v-if="$v.blogCategory.description.$error"
                >Description Field is required</span
              >
              <span class="text-danger" v-if="errors.description"
                >* {{ errors.description[0] }}</span
              >
            </v-col>

            <v-col cols="6" md="6">
              Status
              <v-switch
                v-model="blogCategory.is_active"
                :label="blogCategory.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="btn btn-primary"
          dark
          medium
          @click="createOrUpdate()"
        >
          Save
        </v-btn>
        <v-btn color="btn btn-standard" dark medium @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import BlogCategoryService from "@/service/cms/blog/category/BlogCategoryService";

const blogCategory = new BlogCategoryService();

export default {
  name: "CreateAndUpdate",
  validations: {
    blogCategory: {
      name: { required },
      parent_id: {
        required: requiredIf(function() {
          return !this.blogCategory.is_parent;
        })
      },
      description: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      title: "",
      dialog: false,
      loading: false,
      edit: false,
      errors: [],
      blogCategories: [],
      currentUser: {},
      blogCategory: {
        name: "",
        position: "",
        is_parent: true,
        parent_id: "",
        description: "",
        is_active: true
      },
      editorConfig: {
        toolbar: [
          [
            "Font",
            "FontSize",
            "FontColor",
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
            "TextColor",
            "BGColor"
          ]
        ],
        extraPlugins: ["font", "colorbutton"],
        colorButton_enableMore: true
      }
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.edit = false;
    },
    createBlogCategory() {
      this.title = "Add";
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editBlogCategory(blogCategory) {
      this.title = "Update";
      this.dialog = true;
      this.edit = true;
      this.blogCategory = blogCategory;
    },
    getAllParent() {
      blogCategory
        .getParent()
        .then(response => {
          this.blogCategories = response.data.data;
        })
        .catch(() => {
          this.$snotify.error("Oops something went wrong");
        });
    },

    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      blogCategory
        .create(this.blogCategory)
        .then(response => {
          this.loading = false;
          if (response.data.status === "OK") {
            this.$snotify.success("Blog Category created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit("refresh");
          }
        })
        .catch(err => {
          this.errors = err.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    update() {
      this.loading = true;
      blogCategory
        .update(this.blogCategory.id, this.blogCategory)
        .then(response => {
          this.loading = false;
          if (response.data.status === "OK") {
            this.$snotify.success("Blog Category updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit("refresh");
            this.getAllParent();
          }
        })
        .catch(err => {
          this.errors = err.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.$v.$reset();
      this.blogCategory = {
        name: "",
        position: "",
        is_parent: true,
        parent_id: "",
        description: "",
        is_active: true
      };
    }
  },
  mounted() {
    this.getAllParent();
  }
};
</script>

<style scoped></style>
